<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import loadSections from "@/mixins/loadSections";
import firebaseBindOrder from "@/mixins/firebase/bindOrder";

export default {
  name: "MenuOrderDetailsPaymentThankYou",

  metaInfo: { title: "Menu Order Details Payment Thank You" },

  extends: View,

  mixins: [
    firebaseBindOrder,
    loadSections([
      "public-menu-order-details-payment-thank-you",
      "layout-footer-menu-order-details-payment-thank-you",
    ]),
  ],
};
</script>
